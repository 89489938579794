.header{
    background-color: #060A18;
    color: whitesmoke;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem;
    transition: 1sec;
    position: sticky;
    top: 0px;
    z-index: 99999;
}

.header p{
    margin: 0;
    padding: 0;
}

.header-left{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  
}


.username-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgb(255, 145, 0);
}


.menu-open{
    margin: 0rem 0.5rem 0 0.5rem;
    cursor: pointer;
}

.logo{
    margin: 0rem 0.5rem 0 1rem;

}

.arrow{
    
margin: 0px 1rem 0px 0.2rem;
cursor: pointer;
}

.header-center{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    min-width: 200px;
}

.public_key{text-overflow:ellipsis;
    max-width:50%;
    overflow:hidden;
    white-space:nowrap;
   
}

@media only screen and (max-width:600px){
    .public_key{text-overflow:ellipsis;
        max-width:150px;
      
    }
     
}

@media only screen and (max-width:450px){
    .public_key{text-overflow:ellipsis;
        max-width:50px;
        margin-left: -20px;
      
    }
     
}