.sendCoins-main{
    position: absolute;
    top: 0px;
    left: -100vw;
    width: 100%;

    height: max-content;
  
    min-height: 100vh;
  
    background-image: linear-gradient(160deg,#000000 0%, #090e1f  100%);
    color: whitesmoke;
  
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
   align-items: center;
    -webkit-animation: slideTopNFT 0.5s forwards;
    -webkit-animation-delay: 0.5s;
    animation: slideTopNFT 0.5s forwards;
    animation-delay: 0.1s;
    z-index: 9999;
 
   
    
  
    
}

.sendCoins-main form{
     flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    min-height: 100%;
    width: 60%;
   min-width: 350px;
    background-color: #0b1129;
    padding: 10px;
    margin-top:1rem ;
    border-radius:10px;
    margin-bottom: 13rem;
    
    
}

.sendCoins-main form input{
    width: 80%;
    margin:1rem 0px 1rem 0px;
    padding: 0.5rem;
    border-radius: 5px;
    border:2px solid green;
    outline: none;
    font-size: 1rem;
    text-transform: capitalize;
    background-color: whitesmoke;
}

#checkbox{
    width: 20px;
  
}
.sendCoins-main form label{
    text-align: left;
  
    width: 80%;
}
.sendCoins-main button{
    width: 80%;
    padding: 0.5rem;
    margin:1rem 0px 1rem 0px;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    transition: 1sec;
    background-color: #001d2e;
    background-image: linear-gradient(160deg,rgb(5, 87, 119) 0%, #122974  70%);
    border: none;
    outline: none;
    color: white;
    border-radius: 5px;
   
 
}

@-webkit-keyframes slideRight {
    100% { left: 0px; }
}

@keyframes slideRight {
    100% { left: 0px; }
}