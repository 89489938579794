.nft-main{
    position: absolute;
    top: 0px;
    left: -100vw;
    width: 100%;
    height: max-content;
  
  
    background-image: linear-gradient(160deg,#000000 0%, #090e1f  100%);
    color: whitesmoke;
  
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:space-evenly;
    align-items: flex-start;
    -webkit-animation: slideTopNFT 0.5s forwards;
    -webkit-animation-delay: 0.5s;
    animation: slideTopNFT 0.5s forwards;
    animation-delay: 0.1s;
    z-index: 9999;
  
  
    
}

.nft-heading{
    color: rgb(255, 255, 255);
   
}


.nft-main label{
    text-align: left;
    width: 80%;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1rem;
}
.form-nft{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  width: 100%;
  min-width: 350px;
  margin: 1rem;
  margin-left: 0px;
  margin-right: 0px;
 
 
    
}

.nft-main button{
    width: 80%;
    padding: 0.5rem;
    margin:1rem 0px 1rem 0px;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    transition: 1sec;
    background-color: #001d2e;
    background-image: linear-gradient(160deg,rgb(5, 87, 119) 0%, #122974  70%);
    border: none;
    outline: none;
    color: white;
    border-radius: 5px;
   
 
}

.nft-main .main-sub-box{
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.nft-main button:hover{
    transform: scale(1.05);
}

.nft-main input,textarea{
    width: 80%;
    margin:1rem 0px 1rem 0px;
    padding: 0.5rem;
    border-radius: 5px;
    border:2px solid green;
    outline: none;
    font-size: 1rem;
    text-transform: capitalize;
    background-color: whitesmoke;
  

}

input[type="file" i] {
    color: gray;

}



@-webkit-keyframes slideTopNFT {
    100% { left: 0px; }
}

@keyframes slideTopNFT {
    100% { left: 0px; }
}