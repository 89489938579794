.publicLedger-main{
    position: absolute;
    top: 0px;
    left: -100vw;
    width: 100%;

    height: max-content;
  
    min-height: 100vh;
  
    background-image: linear-gradient(160deg,#000000 0%, #090e1f  100%);
    color: whitesmoke;
  
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content:flex-start;
    align-items: center;
    -webkit-animation: slideTopNFT 0.5s forwards;
    -webkit-animation-delay: 0.5s;
    animation: slideTopNFT 0.5s forwards;
    animation-delay: 0.1s;
    z-index: 9999;
  
    
  
    
}

.table{
    width: 98%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(15, 15, 15);
    margin: 1rem 0rem 1rem 0px;
    flex-wrap: wrap;
    box-shadow: 1px 1px 1px 1px rgb(255, 111, 15);
    border-radius: 7px;
    cursor: pointer;
}

.table:hover{
    box-shadow: 1px 1px 1px 2px rgb(255, 145, 0);
    background-color: rgb(39, 39, 39);
}


.table .row{
    padding: 1rem;
}



@-webkit-keyframes slideRight {
    100% { left: 0px; }
}

@keyframes slideRight {
    100% { left: 0px; }
}