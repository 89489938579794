.footer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #01050f;
    color: grey;
    padding: 1rem 2rem 1rem 2rem;
    font-weight: bold;
    
}

#toolbar{
display: none !important;
}

viewer-toolbar{
    display: none !important;  
}